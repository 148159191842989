import * as React from "react";

import * as Yup from "yup";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import styled from "styled-components";
import moment from "moment-timezone";
import { Page } from "../components/Page";
import DefaultLayout from "../layouts/default";
import { EventApi, EventAttributes } from "../api";
import { defaultAPIConfig } from "../config/defaultApiConfig";
import { InputField } from "../components/InputField";
import Formstyle from "../components/Formstyle";

import EventData from "../components/Events/EventData";
import Grid75 from "../components/Grid.tsx/Grid75";
import GreyBox from "../components/GreyBox";
import { InhaltElem } from "../components/Text/SmallerInhalt";
import ContentWrapper from "../components/Wrappers/ContentWrapper";
import { colors } from "../styles/variables";
import Sponsors from "../components/Sponsors";
import SmallerMainTitle from "../components/Text/SmallerMainTitle";
import EventDates from "../components/Events/EventDates";
import { TextareaField } from "../components/TextareaField";

interface EventTemplateProps {
  pageContext: {
    attributes: EventAttributes;
    id: string;
  };
}

const SubmitButton = styled.button`
  width: max-content;
  min-width: 200px;
  display: block;
  padding: 10px 10px;
  text-transform: uppercase;
  text-align: center;
  font: 23px / 28px "Slate Regular";
  background: ${colors.blue};
  margin: inherit auto;
  color: white;
  border-radius: 10px;
  margin: 0 auto;
  text-decoration: none !important;
  transition: all 0.2s;
  outline: none;
  border: 0;

  &:hover {
    background: ${colors.lightBlue};
    cursor: pointer;
  }
`;

const TicketSchema = Yup.object().shape({
  name: Yup.string()
    .required("This field is required.")
    .min(2, "Please enter min. 2 characters."),
  firstName: Yup.string()
    .required("This field is required.")
    .min(2, "Please enter min. 2 characters."),
  address: Yup.string()
    .required("This field is required.")
    .min(2, "Please enter min. 2 characters."),
  zip: Yup.string()
    .required("This field is required.")
    .min(2, "Please enter min. 2 characters."),
  city: Yup.string()
    .required("This field is required.")
    .min(2, "Please enter min. 2 characters."),
  phone: Yup.string()
    .required("This field is required.")
    .min(2, "Please enter min. 2 characters."),
  email: Yup.string()
    .required("This field is required.")
    .email("Invalid email format")
    .min(2, "Please enter min. 2 characters."),
  termsAndConditionsRead: Yup.boolean().oneOf([true]),
});

const api = new EventApi(defaultAPIConfig());

const BookingTemplate: React.FC<EventTemplateProps> = (props) => {
  // All Data which can be prepared at build time
  const {
    pageContext: {
      attributes: {
        description,
        date,
        labels,
        freeText: { text },
      },
      id,
    },
  } = props;

  const TicketInitialValues = {
    eventId: id,
    tickets: 1,
    name: "",
    firstName: "",
    address: "",
    zip: "",
    city: "",
    phone: "",
    email: "",
    company: "",
    remarks: "",
  };

  const eventName = props.pageContext.attributes.agenda[0].name || "";
  const eventType = props.pageContext.attributes.type || "";
  let eventPlace = "";
  if (props.pageContext.attributes.labels?.length) {
    eventPlace = props.pageContext.attributes.labels[0].name;
  }
  const eventDoorsOpening = props.pageContext.attributes.doorsOpening || "";
  const eventDate = props.pageContext.attributes.date || "";
  const eventEndDate = props.pageContext.attributes.end || "";
  const time =
    moment(props.pageContext.attributes.doorsOpening)
      .tz("Europe/Zurich")
      .format("HH:mm - ") +
    moment(props.pageContext.attributes.end)
      .tz("Europe/Zurich")
      .format(" HH:mm");

  return (
    <DefaultLayout siteSlug={date?.toString()}>
      <Helmet>
        <title>{eventName} - STEP</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={description} />
      </Helmet>
      <Page>
        <ContentWrapper>
          <EventData
            type={eventType}
            date={eventDate}
            endDate={eventEndDate}
            relatedEvents={props.pageContext.relationships.events.data}
            place={eventPlace}
            showtime={props.pageContext.attributes.showtime}
          />

          <SmallerMainTitle color={eventType}>
            RESERVATION: {eventName}
          </SmallerMainTitle>

          <Grid75>
            <InhaltElem>
              {moment(
                props.pageContext.attributes.ticketing?.closingTime
              ).isAfter(new Date()) ? (
                <>
                  <Formstyle>
                    <Formik
                      validationSchema={TicketSchema}
                      initialValues={TicketInitialValues}
                      onSubmit={(values, actions) => {
                        // create tickets with string quantities

                        const makeReservation = async () => {
                          const payload = {
                            reservation: {
                              data: {
                                type: "reservation",
                                attributes: {
                                  ...values,
                                },
                              },
                            },
                          };

                          const reservationRequest = await api.reservationPost(
                            payload,
                            {
                              headers: {
                                accept: "application/vnd.api+json",
                                "content-type": "application/vnd.api+json",
                              },
                            }
                          );

                          if (reservationRequest.data) {
                            // if request worked:
                            window.location = `/event/${values.eventId}/reservationcompleted/`;
                          }
                        };

                        makeReservation();

                        // alert(JSON.stringify(values, null, 2));
                        actions.setSubmitting(false);
                      }}
                    >
                      {() => (
                        <Form>
                          <InputField name="name" label="Family name *" />
                          <InputField name="firstName" label="First name *" />
                          <InputField name="company" label="Company" />
                          <InputField name="address" label="Address *" />
                          <InputField name="zip" label="Zip *" />
                          <InputField name="city" label="City *" />
                          <InputField name="phone" label="Phone *" />
                          <InputField name="email" label="Mail *" />
                          <TextareaField name="remarks" label="Comments" />
                          <SubmitButton type="submit">
                            SUBMIT RESERVATION
                          </SubmitButton>
                          {/** <DebugForm /> */}
                        </Form>
                      )}
                    </Formik>
                  </Formstyle>
                </>
              ) : (
                <>Registration already closed.</>
              )}
              <br />
              <br />
            </InhaltElem>
            <GreyBox>
              <InhaltElem>
                <b>Date</b>
                <br />
                <EventDates
                  relatedEvents={props.pageContext.relationships.events.data}
                  date={eventDate}
                  showtime={props.pageContext.attributes.showtime}
                  end={eventEndDate}
                />
                <br />
                <b>Venue</b>
                <br />
                {props.pageContext.relationships.location.data.attributes.name}
                <br />
                {
                  props.pageContext.relationships.location.data.attributes
                    .address
                }
                <br />
                {
                  props.pageContext.relationships.location.data.attributes.zip
                }{" "}
                {props.pageContext.relationships.location.data.attributes.city}
                <br />
                {
                  props.pageContext.relationships.location.data.attributes
                    .country
                }
                <br />
                <br />
                {(props.pageContext.attributes.reservations?.active ||
                  props.pageContext.attributes.ticketing?.active) && (
                  <>
                    <b>Registration by</b>
                    <br />
                    {moment(props.pageContext.attributes.ticketing?.closingTime)
                      .tz("Europe/Zurich")
                      .format("dddd, DD MMMM YYYY")
                      .toUpperCase()}
                    <br />
                    <br />
                  </>
                )}
                {text && (
                  <>
                    <b>CPD</b>
                    <br />
                    {text}
                    <br />
                    <br />
                  </>
                )}
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.pageContext.attributes.shortDescription,
                  }}
                />
              </InhaltElem>
            </GreyBox>
          </Grid75>

          <Sponsors labels={labels} />
        </ContentWrapper>
      </Page>
    </DefaultLayout>
  );
};

export default BookingTemplate;
